@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,500,600');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Use body font  */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  outline: none !important;
}

body {
  background: #f8f8f9;
  color: #3d3e40;
  font-family: Fira Sans, sans-serif;
}

.container {
  max-width: 900px !important;
}
