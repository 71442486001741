.ReactTable {
  background-color: transparent !important;
  border: none !important;
}

.rt-table {
  border-radius: 2px !important;
  box-shadow: 0 0 12px 0 #e6e6e9;
  background-color: rgb(255, 255, 255);
}

.rt-th {
  height: 72px;
  display: flex;
  align-items: center;
  border-right: none !important;
}

.rt-resizable-header-content {
  padding-top: 13px;
  padding-left: 24px;
  font-weight: 500;
}

.rt-thead,
.rt-tbody {
  font-size: 14px;
}

.rt-tbody .rt-tr {
  cursor: pointer;
}

.rt-tbody .rt-tr.no-cursor {
  cursor: default !important;
}

.rt-tr.td-aborted {
  background-color: #fff7f7 !important;
}

.rt-tr.td-waiting {
  background-color: #fffcf7 !important;
}

.rt-tr.td-archived {
  opacity: 0.2 !important;
}

.rt-td {
  display: flex;
  align-items: center;
  border-right: none !important;
  padding-left: 24px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.rt-thead {
  box-shadow: none !important;
}

.-striped .rt-tr.-odd {
  background: #fbfbfb !important;
}

.-striped .rt-tr.td-aborted.-odd {
  background-color: #fff4f4 !important;
}

.-striped .rt-tr.td-waiting.-odd {
  background-color: #fffcf7 !important;
}

.-striped .rt-tr.td-archived.-odd {
  background: #fbfbfb !important;
  opacity: 0.5 !important;
}

.-highlight .rt-tbody .rt-tr:hover:not(.-padRow) {
  background: rgba(0, 0, 0, 0.05) !important;
}

.-highlight .rt-tbody .rt-tr.td-aborted:hover:not(.-padRow) {
  background: rgba(255, 0, 0, 0.1) !important;
}

.-highlight .rt-tbody .rt-tr.td-waiting:hover:not(.-padRow) {
  background: rgba(255, 159, 0, 0.1) !important;
}

.-highlight .rt-tbody .rt-tr.td-archived:hover:not(.-padRow) {
  background: rgba(0, 0, 0, 0.2) !important;
}

.rt-tr-group {
  border-bottom: none !important;
}

.ReactTable .-pagination {
  margin-top: 25px;
}
