@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,500,600);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.uh-spin {
  -webkit-animation: spin 4s linear infinite;
          animation: spin 4s linear infinite;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Use body font  */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  outline: none !important;
}

body {
  background: #f8f8f9;
  color: #3d3e40;
  font-family: Fira Sans, sans-serif;
}

.container {
  max-width: 900px !important;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.uh-full-width {
  width: 100%;
}

.uh-btn-inner {
  align-items: center;
  display: flex;
}

.uh-btn-label {
  flex-grow: 1;
}

.uh-btn-icon {
  align-items: center;
  display: flex;
  fill: #5ca7df;
  margin-right: 10px;
}

.uh-btn {
  background: none;
  border: 2px solid transparent;
  border-radius: 20px;
  color: #5ca7df;
  cursor: pointer;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 1px;
  line-height: 16px;
  outline: none;
  padding: 0 20px;
  text-transform: uppercase;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}

.uh-btn:hover {
  background: #ebecf9;
}

.uh-btn:active {
  background: #d4d7f2;
}

.uh-btn:disabled {
  background: none;
  color: rgba(184, 186, 191, 0.5);
  cursor: default;
}

.uh-btn:disabled .uh-btn-icon {
  fill: rgba(184, 186, 191, 0.5);
}

/* Primary */

.uh-btn-primary {
  background: rgba(255, 255, 255, 0.7);
  border-color: white;
}

.uh-btn-primary:disabled {
  background: rgba(255, 255, 255, 0.7);
}

/* Outline */

.uh-btn-outline {
  border-color: #5ca7df;
}

.uh-btn-outline:disabled {
  border-color: rgba(184, 186, 191, 0.5);
}

/* IconButton */

.uh-btn-round {
  width: 36px;
  border-radius: 50%;
  border: 1px solid #5ca7df;
  padding: 0px;
}

.uh-btn-round:disabled {
  border-color: rgba(184, 186, 191, 0.5);
}

.uh-btn-round .uh-btn-icon {
  margin-right: 0px;
}

.uh-btn-round .uh-btn-inner {
  justify-content: center;
}

.ReactTable {
  background-color: transparent !important;
  border: none !important;
}

.rt-table {
  border-radius: 2px !important;
  box-shadow: 0 0 12px 0 #e6e6e9;
  background-color: rgb(255, 255, 255);
}

.rt-th {
  height: 72px;
  display: flex;
  align-items: center;
  border-right: none !important;
}

.rt-resizable-header-content {
  padding-top: 13px;
  padding-left: 24px;
  font-weight: 500;
}

.rt-thead,
.rt-tbody {
  font-size: 14px;
}

.rt-tbody .rt-tr {
  cursor: pointer;
}

.rt-tbody .rt-tr.no-cursor {
  cursor: default !important;
}

.rt-tr.td-aborted {
  background-color: #fff7f7 !important;
}

.rt-tr.td-waiting {
  background-color: #fffcf7 !important;
}

.rt-tr.td-archived {
  opacity: 0.2 !important;
}

.rt-td {
  display: flex;
  align-items: center;
  border-right: none !important;
  padding-left: 24px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.rt-thead {
  box-shadow: none !important;
}

.-striped .rt-tr.-odd {
  background: #fbfbfb !important;
}

.-striped .rt-tr.td-aborted.-odd {
  background-color: #fff4f4 !important;
}

.-striped .rt-tr.td-waiting.-odd {
  background-color: #fffcf7 !important;
}

.-striped .rt-tr.td-archived.-odd {
  background: #fbfbfb !important;
  opacity: 0.5 !important;
}

.-highlight .rt-tbody .rt-tr:hover:not(.-padRow) {
  background: rgba(0, 0, 0, 0.05) !important;
}

.-highlight .rt-tbody .rt-tr.td-aborted:hover:not(.-padRow) {
  background: rgba(255, 0, 0, 0.1) !important;
}

.-highlight .rt-tbody .rt-tr.td-waiting:hover:not(.-padRow) {
  background: rgba(255, 159, 0, 0.1) !important;
}

.-highlight .rt-tbody .rt-tr.td-archived:hover:not(.-padRow) {
  background: rgba(0, 0, 0, 0.2) !important;
}

.rt-tr-group {
  border-bottom: none !important;
}

.ReactTable .-pagination {
  margin-top: 25px;
}

