.uh-full-width {
  width: 100%;
}

.uh-btn-inner {
  align-items: center;
  display: flex;
}

.uh-btn-label {
  flex-grow: 1;
}

.uh-btn-icon {
  align-items: center;
  display: flex;
  fill: #5ca7df;
  margin-right: 10px;
}

.uh-btn {
  background: none;
  border: 2px solid transparent;
  border-radius: 20px;
  color: #5ca7df;
  cursor: pointer;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 1px;
  line-height: 16px;
  outline: none;
  padding: 0 20px;
  text-transform: uppercase;
  transition: background 0.4s;
}

.uh-btn:hover {
  background: #ebecf9;
}

.uh-btn:active {
  background: #d4d7f2;
}

.uh-btn:disabled {
  background: none;
  color: rgba(184, 186, 191, 0.5);
  cursor: default;
}

.uh-btn:disabled .uh-btn-icon {
  fill: rgba(184, 186, 191, 0.5);
}

/* Primary */

.uh-btn-primary {
  background: rgba(255, 255, 255, 0.7);
  border-color: white;
}

.uh-btn-primary:disabled {
  background: rgba(255, 255, 255, 0.7);
}

/* Outline */

.uh-btn-outline {
  border-color: #5ca7df;
}

.uh-btn-outline:disabled {
  border-color: rgba(184, 186, 191, 0.5);
}

/* IconButton */

.uh-btn-round {
  width: 36px;
  border-radius: 50%;
  border: 1px solid #5ca7df;
  padding: 0px;
}

.uh-btn-round:disabled {
  border-color: rgba(184, 186, 191, 0.5);
}

.uh-btn-round .uh-btn-icon {
  margin-right: 0px;
}

.uh-btn-round .uh-btn-inner {
  justify-content: center;
}
